export default {

  acceptTerms() {
    this.checkbox.checked = true;
  },

  handleClick() {
    if (this.checkbox.checked) {
      this.checkbox.checked = false;

      return;
    }
    $('#terms-modal').modal('show');
  },

  handleKey(event) {
    if (event.code === 'Space') {
      this.handleClick();
    }
  },

  init() {
    this.checkbox = document.getElementById('accept-terms-checkbox');
    this.control = document.getElementById('accept-terms-control');
    this.accept = document.getElementById('accept-terms-button');

    this.control.addEventListener('click', () => this.handleClick());
    this.control.addEventListener('keyup', event => this.handleKey(event));
    this.accept.addEventListener('click', () => this.acceptTerms());
  },

};
