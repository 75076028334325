import form from './modal/form';
import flash from './vendor/flash'

const Tourn = {
  modal: {
    form: form,
  },
  vendor: {
    flash: flash,
  }
};

export {Tourn};
